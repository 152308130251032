@mixin help-theme($theme) {
	$background: map-get($theme, background);
	$foreground: map-get($theme, foreground);
	$primary: map-get($theme, primary);
	$accent: map-get($theme, accent);
	$is-dark: map-get($theme, is-dark);

	#help {
		.btn-help {
			background-color: #fff;
			color: #2c3e50;

			&:hover {
				color: #fff;
				background-color: var(--feedbalia-color-primary-400);
			}

			span {
				display: block;
				width: 100%;
				text-align: center;
			}
		}

		.card-border-top {
			.search-container {
				background-color: var(--feedbalia-color-primary-400);

				.search-wrapper {
					background: map-get($background, card);

					.search {
						mat-icon {
							color: map-get($foreground, secondary-text);
						}

						input {
							background: map-get($background, card);
							color: map-get($foreground, text);
						}
					}
				}
			}
		}
	}

	.hightligth-text {
		background-color: yellow;
	}
}
