// Import Fuse core library
@import "/src/@fuse/scss/core";

// Import app.theme.scss
@import "/src/app/app.theme";

@import "/node_modules/@ctrl/ngx-emoji-mart/picker";

.select-panel {
	max-width: none !important;
}

.incidencia-medica {
	background: rgb(250, 184, 184);
	border-radius: 100%;
}

.incidencia-otra {
	background: rgb(164, 185, 255);
	border-radius: 100%;
}

.tcenter {
	text-align: center !important;
}

.p-1-r {
	padding: 1rem;
}

.gap-1 {
	gap: 1rem;
}

.p-10 {
	padding: 10px;
}

.mdc-button {
	min-width: auto !important;
}

.image-wrapper {
	display: flex;
	align-self: center;

	max-height: 100%;
	border: 1px solid rgba(0, 0, 0, 0.2);
	background-color: rgba(0, 0, 0, 0.1);
	border-radius: 1rem;
	padding: 1.5rem;

	&.clickable {
		transition: background-color 0.2s;
		cursor: pointer;

		&:hover {
			background-color: rgba(0, 0, 0, 0.15);
		}
	}

	img {
		border-radius: inherit;
	}
}

.mat-icon {
	overflow: visible !important;
}

.mat-mdc-button > .mat-icon,
.mat-mdc-unelevated-button > .mat-icon,
.mat-mdc-raised-button > .mat-icon,
.mat-mdc-outlined-button > .mat-icon {
	font-size: x-large !important;
}

.img-circle {
	border-radius: 50%;
	aspect-ratio: 1;
}

.md-drppicker {
	width: 556px !important;
}
.mat-stepper-vertical,
.mat-stepper-horizontal {
	background: transparent !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
	background-color: var(--feedbalia-color-background-500, #fff) !important;
}

table {
	background-color: transparent !important;
}

mat-paginator {
	border-radius: 4px;
	border: 2px solid var(--feedbalia-color-primary-500);
	background-color: transparent !important;
}

mat-dialog-content {
	padding-top: 5px !important;
}
