@mixin sied-theme($theme) {
	$background: map-get($theme, background);
	$foreground: map-get($theme, foreground);
	$primary: map-get($theme, primary);
	$accent: map-get($theme, accent);
	$is-dark: map-get($theme, is-dark);

	.panel-plan-accion {
		border-color: var(--feedbalia-color-primary-700);
	}

	.no-select {
		opacity: 0.3;
	}

	#sied {
		.btn-help {
			color: var(--feedbalia-color-primary-700);
		}

		.mat-vertical-stepper-header {
			pointer-events: none !important;
			height: 10px;
		}

		.mat-vertical-content {
			display: none !important;
		}

		.mat-step-label-selected {
			font-weight: 400;
		}
		.mat-step-icon-selected {
			background-color: rgba(0, 0, 0, 0.54);
		}
		/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
		.mat-tab-label,
		.mat-mdc-tab-link {
			color: var(--feedbalia-color-primary-500);
			font-weight: 700;
			flex: 1;
		}
		.mat-ink-bar {
			background-color: var(--feedbalia-color-primary-400);
		}
		.md-drppicker {
			td.active {
				background-color: var(--feedbalia-color-primary-400);
				&:hover {
					background-color: var(--feedbalia-color-primary-400);
				}
			}

			.btn {
				background-color: var(--feedbalia-color-primary-400);
				&:hover,
				&:focus {
					background-color: var(--feedbalia-color-primary-400);
				}
			}

			.ranges ul li button.active {
				background-color: var(--feedbalia-color-primary-400);
				border-radius: 4px;
			}
		}
		.mat-mdc-form-field[dense] {
			/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
			.mat-form-field-infix {
				padding: 0.4em 0px !important;
				border-top: 0px !important;
				top: -5px;
			}
			/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
			.mat-form-field-label-wrapper {
				top: -1em;
			}
			/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
			&.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
				transform: translateY(-1.2em) scale(0.75);
			}
			/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
			.mat-form-field-wrapper {
				padding-bottom: 0;
			}
		}
	}

	#evaluacion {
		.card-part-right {
			border-color: var(--feedbalia-color-primary-700);
		}
		/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */
		.mat-progress-bar-buffer {
			background-color: var(--feedbalia-color-primary-400);
		}
		/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */
		.mat-progress-bar-fill::after {
			background-color: var(--feedbalia-color-primary-700);
		}

		.stepTimeLine {
			.mat-stepper-vertical-line::before {
				top: -12px;
				bottom: -12px;
			}
			.mat-vertical-stepper-header {
				padding-top: 12px;
				padding-bottom: 12px;
			}
			.mat-step-header {
				.mat-step-icon.mat-step-icon-selected,
				.mat-step-icon.mat-step-icon-state-done,
				.mat-step-icon.mat-step-icon-state-edit {
					background-color: var(--feedbalia-color-primary-700);
				}
				.mat-step-icon {
					background-color: var(--feedbalia-color-primary-500);
				}

				.mat-step-label.mat-step-label-active.mat-step-label-selected {
					color: var(--feedbalia-color-primary-700);
				}
				.mat-step-label.mat-step-label-active {
					color: var(--feedbalia-color-primary-500);
				}
			}
			.mat-vertical-content {
				padding-bottom: 8px;
			}
		}
	}

	.mat-mdc-option {
		word-wrap: break-word !important;
		white-space: normal !important;
		font-size: 12px !important;
	}
	/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
	.mat-option-text {
		line-height: initial !important;
		font-size: 12px !important;
	}
}
