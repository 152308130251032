// -----------------------------------------------------------------------------------------------------
@use "@angular/material" as mat;
// @ Theming
// -----------------------------------------------------------------------------------------------------
@mixin typography-theme($theme) {
	$background: map-get($theme, background);
	$accent: map-get($theme, accent);
	$is-dark: map-get($theme, is-dark);

	a {
		color: var(--feedbalia-color-primary-500);
	}

	code {
		&:not(.highlight) {
			background-color: map-get($background, app-bar);
		}
	}

	.changelog {
		.entry {
			background-color: map-get($background, card);
		}
	}

	.text-boxed {
		@if ($is-dark) {
			background-color: rgba(255, 255, 255, 0.12);
			color: rgba(255, 255, 255, 0.54);
		} @else {
			background-color: rgba(0, 0, 0, 0.12);
			color: rgba(0, 0, 0, 0.54);
		}
	}
}

// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------
html {
	font-size: 62.5%;
	font-family: "Muli", "Helvetica Neue", "Arial", sans-serif;
	line-height: 1.4 !important;
	letter-spacing: -0.1px !important;
}

body {
	font-size: 14px;
}

html,
body {
	-webkit-font-smoothing: auto;
	-moz-osx-font-smoothing: auto;
}

// Headings
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-weight: normal;
}

h1,
.h1 {
	font-size: 24px;
}

h2,
.h2 {
	font-size: 20px;
}

h3,
.h3 {
	font-size: 16px;
}

h4,
.h4 {
	font-size: 15px;
}

h5,
.h5 {
	font-size: 13px;
}

h6,
.h6 {
	font-size: 12px;
}

// Links
a {
	text-decoration: none;

	&:not(.mat-mdc-button):not(.mat-mdc-raised-button):not(.mat-mdc-unelevated-button):not(.mat-mdc-outlined-button):not(.mat-mdc-icon-button):not(
			.mat-mdc-fab
		):not(.mat-mdc-mini-fab) {
		&:hover,
		&:active {
			text-decoration: underline;
		}
	}
}

// Abbr
abbr {
	cursor: help;
	border-bottom: 1px dotted rgba(0, 0, 0, 0.54);
}

// Blockquote
blockquote {
	border-left: 3px solid rgba(0, 0, 0, 0.12);
	font-style: italic;
	margin: 1em 0;
	padding-left: 16px;

	footer {
		font-style: normal;

		&:before {
			content: "\2014 \00A0";
		}
	}

	&.reverse {
		border-left: none;
		border-right: 3px solid rgba(0, 0, 0, 0.12);
		text-align: right;
		padding-left: 0;
		padding-right: 16px;

		footer {
			&:before {
				content: "";
			}

			&:after {
				content: "\2014 \00A0";
			}
		}
	}
}

// Code
code {
	font-family: "Monaco", "Menlo", "Consolas", "Ubuntu Mono", monospace;

	&:not(.highlight) {
		color: #106cc8;
		padding: 2px 3px;
		border-radius: 2px;
		line-height: 1.7;
	}
}

// Definition lists
dl {
	dt {
		font-weight: bold;
	}

	dd {
		margin: 4px 0 16px 0;
	}
}

// Mark
mark {
	background: #f7f49a;
}

// Pre
pre {
	line-height: 1.6;
	margin: 8px 16px;
	white-space: pre-wrap;
}

// Small
small {
	font-size: 80%;
}

// Table
table {
	thead {
		tr {
			th {
				text-align: left;
			}
		}
	}
}

// Text format helpers
.text-italic {
	font-style: italic;
}

.text-semibold {
	font-weight: 600;
}

.text-bold,
strong {
	font-weight: 700;
}

.text-strike {
	text-decoration: line-through;
}

.text-super {
	vertical-align: super;
}

.text-sub {
	vertical-align: sub;
}

.text-capitalize {
	text-transform: capitalize;
}

.text-lowercase {
	text-transform: lowercase;
}

.text-uppercase {
	text-transform: uppercase;
}

// Text align helpers
.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

// Font weight helpers
@for $weight from 1 through 9 {
	.font-weight-#{$weight * 100} {
		font-weight: #{$weight * 100};
	}
}

// Font size helpers
@for $size from 1 through 60 {
	.font-size-#{$size * 2} {
		font-size: #{$size * 2}px;
	}
}

// Line height helpers
@for $lineHeight from 1 through 60 {
	.line-height-#{$lineHeight * 2} {
		line-height: #{$lineHeight * 2}px;
	}
}

.line-height-1 {
	line-height: 1;
}

.line-height-1\.25 {
	line-height: 1.25;
}

.line-height-1\.5,
.line-height-1\.50 {
	line-height: 1.5;
}

.line-height-1\.75 {
	line-height: 1.75;
}

// Letter spacing helpers
.letter-spacing-condensed {
	letter-spacing: -0.02em;
}

.letter-spacing-normal {
	letter-spacing: normal;
}

.letter-spacing-relaxed {
	letter-spacing: 0.02em;
}

// Boxed text
.text-boxed {
	border-radius: 2px;
	padding: 4px 8px;
	margin: 0 8px;
	font-size: 11px;
	font-weight: 600;
	white-space: nowrap;
}

// Truncate
.text-truncate {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

// Nowrap
.text-nowrap {
	white-space: nowrap;
}

// Changelog
.changelog {
	.entry {
		margin-bottom: 24px;
		padding: 24px 32px;
		@include mat.elevation(2);

		> .title {
			display: flex;
			align-items: center;
			margin-bottom: 24px;

			.version {
				font-size: 24px;
			}

			.date {
				margin-left: 8px;
				font-size: 17px;
				opacity: 0.54;
			}
		}

		.groups {
			div {
				margin-bottom: 32px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			.title {
				display: inline-flex;
				font-size: 13px;
				color: white;
				letter-spacing: 0.015em;
				line-height: 1;
				padding: 5px 8px;
				border-radius: 2px;
			}

			.breaking-changes {
				.title {
					background: #f44336;
				}
			}

			.new {
				.title {
					background: #43a047;
				}
			}

			.improved {
				.title {
					background: #673ab7;
				}
			}

			.fixed {
				.title {
					background: #2196f3;
				}
			}

			ul {
				padding-left: 24px;

				li {
					margin-bottom: 6px;
					letter-spacing: 0.015em;

					ul {
						margin-top: 6px;
					}
				}
			}
		}
	}
}

// Message boxes
.message-box {
	padding: 16px;
	background: #607d8b;
	border-left: 6px solid #37474f;
	color: rgba(255, 255, 255, 1);

	&.error {
		background: #ef5350;
		border-left-color: #b71c1c;
	}

	&.warning {
		background: #ffecb3;
		border-left-color: #ffc107;
		color: rgba(0, 0, 0, 0.87);
	}

	&.success {
		background: #4caf50;
		border-left-color: #2e7d32;
	}

	&.info {
		background: #b3e5fc;
		border-left-color: #03a9f4;
		color: rgba(0, 0, 0, 0.87);
	}
}
