// -----------------------------------------------------------------------------------------------------
@use "@angular/material" as mat;
// @ Custom color maps for Fuse
// -----------------------------------------------------------------------------------------------------
@import "./feedbalia.colors.scss";

$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$fuse-white: (
	500: white,
	contrast: (
		500: $dark-primary-text,
	),
);

$fuse-black: (
	500: black,
	contrast: (
		500: $light-primary-text,
	),
);

$fuse-navy: (
	50: #ececee,
	100: #c5c6cb,
	200: #9ea1a9,
	300: #7d818c,
	400: #5c616f,
	500: #3c4252,
	600: #353a48,
	700: #2d323e,
	800: #262933,
	900: #1e2129,
	A100: #c5c6cb,
	A200: #9ea1a9,
	A400: #5c616f,
	A700: #2d323e,
	contrast: (
		50: $dark-primary-text,
		100: $dark-primary-text,
		200: $dark-primary-text,
		300: $light-primary-text,
		400: $light-primary-text,
		500: $light-primary-text,
		600: $light-primary-text,
		700: $light-primary-text,
		800: $light-primary-text,
		900: $light-primary-text,
		A100: $dark-primary-text,
		A200: $light-primary-text,
		A400: $light-primary-text,
		A700: $light-primary-text,
	),
);

// Generate Fuse color classes for custom palettes
$custom_palettes: (
	fuse-white: $fuse-white,
	fuse-black: $fuse-black,
	fuse-navy: $fuse-navy,
	feedbalia-primary: $feedbalia-primary,
	feedbalia-navbar: $feedbalia-navbar,
	feedbalia-navbar2: $feedbalia-navbar2,
	feedbalia-sidebar: $feedbalia-sidebar,
	feedbalia-navbar-logo: $feedbalia-navbar-logo,
	admin-sidebar: $admin-sidebar,
	admin-navbar: $admin-navbar,
);

@include fuse-color-classes($custom_palettes);

// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------

// Angular Material typography
$typography: mat.define-typography-config(
	$font-family: "Muli, Helvetica Neue, Arial, sans-serif",
	$headline-6: mat.define-typography-level(20px, 32px, 600),
	$subtitle-2: mat.define-typography-level(14px, 24px, 600),
	$button: mat.define-typography-level(14px, 14px, 600),
	$body-1: mat.define-typography-level(16px, 1.125, 400) // line-height must be unitless !!!,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
);

// Setup the typography
@include mat.all-component-typographies($typography);

// -----------------------------------------------------------------------------------------------------
// @ Component theming
// -----------------------------------------------------------------------------------------------------

// Import app and page component themes here to enable theming for them
@import "/src/app/layout/components/chat-panel/chat-panel.theme";
@import "/src/app/layout/components/toolbar/toolbar.theme";

@import "/src/app/main/login/login.theme";
@import "/src/app/main/codigo-seguridad/codigo-seguridad.theme";
@import "/src/app/main/chat/chat.theme";
@import "/src/app/main/inicio/inicio.theme";
@import "/src/app/main/perfil/perfil.theme";
@import "/src/app/main/publicaciones/publicaciones.theme";
@import "/src/app/main/help/help.theme";
@import "/src/app/main/shop/shop.theme";
@import "/src/app/main/expenses/expenses.theme";
@import "/src/app/main/sied/sied.theme";
@import "/src/app/main/vuelta-d/vuelta-d.theme";

// Define a mixin for easier access
@mixin components-theme($theme) {
	// Layout components
	@include chat-panel-theme($theme);
	@include toolbar-theme($theme);
	@include chat-theme($theme);
	@include login-theme($theme);
	@include codigo-seguridad-theme($theme);
	@include inicio-theme($theme);
	@include perfil-theme($theme);
	@include publicaciones-theme($theme);
	@include help-theme($theme);
	@include shop-theme($theme);
	@include expenses-theme($theme);
	@include sied-theme($theme);
	@include vuelta-d-theme($theme);
}

// -----------------------------------------------------------------------------------------------------
// @ Define the default theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$default-primary-palette: mat.define-palette($fuse-navy);
$default-accent-palette: mat.define-palette($feedbalia-primary, 500, 400, 700);
$default-accent-palette: mat.define-palette(mat.$teal-palette, 600, 400, 700);
$default-warn-palette: mat.define-palette(mat.$red-palette);

$admin-primary-palette: mat.define-palette($fuse-navy);
$admin-accent-palette: mat.define-palette($admin-primary, 500, 400, 700);
$admin-warn-palette: mat.define-palette(mat.$red-palette);

$theme: mat.define-light-theme(
	(
		color: (
			primary: $default-primary-palette,
			accent: $default-accent-palette,
			warn: $default-warn-palette,
		),
	)
);

$themeAdmin: mat.define-light-theme(
	(
		color: (
			primary: $admin-primary-palette,
			accent: $admin-accent-palette,
			warn: $admin-warn-palette,
		),
	)
);

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-default {
	// Create an Angular Material theme from the $theme map
	@include mat.all-component-themes($theme);

	// Apply the theme to the Fuse Core
	@include fuse-core-theme($theme);

	// Apply the theme to the user components
	@include components-theme($theme);

	// Generate Fuse color classes for primary, accent and warn colors
	$palettes: (
		primary: $default-primary-palette,
		accent: $default-accent-palette,
		warn: $default-warn-palette,
	);

	@include fuse-color-classes($palettes);
}

body.administracion-default {
	// Create an Angular Material theme from the $theme map
	@include mat.all-component-themes($themeAdmin);

	// Apply the theme to the Fuse Core
	@include fuse-core-theme($themeAdmin);

	// Apply the theme to the user components
	@include components-theme($themeAdmin);

	// Generate Fuse color classes for primary, accent and warn colors
	$palettes: (
		primary: $admin-primary-palette,
		accent: $admin-accent-palette,
		warn: $admin-warn-palette,
	);

	@include fuse-color-classes($palettes);
}

// // -----------------------------------------------------------------------------------------------------
// // @ Define a yellow light theme
// // -----------------------------------------------------------------------------------------------------

// // Define the primary, accent and warn palettes
// $yellow-light-theme-primary-palette: mat.define-palette($fuse-navy, 600, 400, 700);
// $yellow-light-theme-accent-palette: mat.define-palette(mat.$yellow-palette, 600, 400, 700);
// $yellow-light-theme-warn-palette: mat.define-palette(mat.$red-palette);

// // Create the Material theme object
// $yellow-light-theme: mat.define-light-theme(
// 	$yellow-light-theme-primary-palette,
// 	$yellow-light-theme-accent-palette,
// 	$yellow-light-theme-warn-palette
// );

// // Add ".theme-yellow-light" class to the body to activate this theme.
// // Class name must start with "theme-" !!!
// body.theme-yellow-light {
// 	// Generate the Angular Material theme
// 	@include mat.all-component-themes($yellow-light-theme);

// 	// Apply the theme to the Fuse Core
// 	@include fuse-core-theme($yellow-light-theme);

// 	// Apply the theme to the user components
// 	@include components-theme($yellow-light-theme);

// 	// Generate Fuse color classes for primary, accent and warn colors
// 	$palettes: (
// 		primary: $yellow-light-theme-primary-palette,
// 		accent: $yellow-light-theme-accent-palette,
// 		warn: $yellow-light-theme-warn-palette,
// 	);

// 	@include fuse-color-classes($palettes);
// }

// // -----------------------------------------------------------------------------------------------------
// // @ Define a blue-gray dark theme
// // -----------------------------------------------------------------------------------------------------

// // Define the primary, accent and warn palettes
// $blue-gray-dark-theme-primary-palette: mat.define-palette(mat.$blue-palette);
// $blue-gray-dark-theme-accent-palette: mat.define-palette(mat.$blue-gray-palette);
// $blue-gray-dark-theme-warn-palette: mat.define-palette(mat.$red-palette);

// // Create the Material theme object
// $blue-gray-dark-theme: mat.define-dark-theme(
// 	$blue-gray-dark-theme-primary-palette,
// 	$blue-gray-dark-theme-accent-palette,
// 	$blue-gray-dark-theme-warn-palette
// );

// // Add ".theme-blue-gray-dark" class to the body to activate this theme.
// // Class name must start with "theme-" !!!
// body.theme-blue-gray-dark {
// 	// Generate the Angular Material theme
// 	@include mat.all-component-themes($blue-gray-dark-theme);

// 	// Apply the theme to the Fuse Core
// 	@include fuse-core-theme($blue-gray-dark-theme);

// 	// Apply the theme to the user components
// 	@include components-theme($blue-gray-dark-theme);

// 	// Generate Fuse color classes for primary, accent and warn colors
// 	$palettes: (
// 		primary: $blue-gray-dark-theme-primary-palette,
// 		accent: $blue-gray-dark-theme-accent-palette,
// 		warn: $blue-gray-dark-theme-warn-palette,
// 	);

// 	@include fuse-color-classes($palettes);
// }

// // -----------------------------------------------------------------------------------------------------
// // @ Define a pink dark theme
// // -----------------------------------------------------------------------------------------------------

// // Define the primary, accent and warn palettes
// $pink-dark-theme-primary-palette: mat.define-palette(mat.$pink-palette);
// $pink-dark-theme-accent-palette: mat.define-palette(mat.$pink-palette);
// $pink-dark-theme-warn-palette: mat.define-palette(mat.$red-palette);

// // Create the Material theme object
// $pink-dark-theme: mat.define-dark-theme($pink-dark-theme-primary-palette, $pink-dark-theme-accent-palette, $pink-dark-theme-warn-palette);

// // Add ".theme-pink-dark" class to the body to activate this theme.
// // Class name must start with "theme-" !!!
// body.theme-pink-dark {
// 	// Generate the Angular Material theme
// 	@include mat.all-component-themes($pink-dark-theme);

// 	// Apply the theme to the Fuse Core
// 	@include fuse-core-theme($pink-dark-theme);

// 	// Apply the theme to the user components
// 	@include components-theme($pink-dark-theme);

// 	// Generate Fuse color classes for primary, accent and warn colors
// 	$palettes: (
// 		primary: $pink-dark-theme-primary-palette,
// 		accent: $pink-dark-theme-accent-palette,
// 		warn: $pink-dark-theme-warn-palette,
// 	);

// 	@include fuse-color-classes($palettes);
// }

// -----------------------------------------------------------------------------------------------------
// Using the new aesthetic.

body.theme-default.new-aesthetic,
body.administracion-default.new-aesthetic {
	background: var(--feedbalia-color-background-500) !important;

	.mat-mdc-raised-button,
	.mat-mdc-unelevated-button {
		background-color: var(--feedbalia-color-accent-500) !important;
		color: var(--feedbalia-color-primary-500) !important;
	}

	.mat-accent {
		background-color: var(--feedbalia-color-accent-500) !important;
		color: var(--feedbalia-color-primary-500) !important;
	}
	/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
	mat-checkbox.mat-mdc-checkbox {
		background-color: transparent !important;
	}
	/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
	.mat-checkbox-checked.mat-accent .mat-checkbox-background {
		background-color: var(--feedbalia-color-primary-500) !important;
	}

	.mat-mdc-slide-toggle.mat-checked {
		/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
		.mat-slide-toggle-bar {
			background: color-mix(in srgb, var(--feedbalia-color-primary-500) 54%, transparent) !important;
		}
		/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
		.mat-slide-toggle-thumb {
			background-color: var(--feedbalia-color-primary-500) !important;
		}
	}

	.nav.vertical {
		.active.accent {
			background-color: var(--feedbalia-color-accent-500) !important;
			color: var(--feedbalia-color-primary-500) !important;
		}

		.nav-link {
			background-color: var(--feedbalia-color-primary-500) !important;
			color: var(--feedbalia-color-menutext-500) !important;
		}
	}

	.btn-like,
	.btn-responder {
		color: var(--feedbalia-color-primary-400) !important;
		border-color: var(--feedbalia-color-primary-400) !important;

		&.active {
			background-color: var(--feedbalia-color-primary-400) !important;
			color: var(--feedbalia-color-background-500) !important;
		}
	}

	.btn-comments {
		color: var(--feedbalia-color-primary-400) !important;

		&:hover {
			opacity: 0.6;
		}
	}
	/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
	/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
	.mat-button-toggle-group .mat-button-toggle {
		/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
		&.mat-button-toggle-checked {
			background-color: var(--feedbalia-color-accent-500) !important;
			color: var(--feedbalia-color-primary-500) !important;
		}
	}

	mat-toolbar button {
		.secondary-text {
			color: var(--feedbalia-color-menutext-500) !important;
		}
	}

	.person-container {
		color: var(--feedbalia-color-accent-500);
	}

	.item-badge {
		border-bottom: 1px solid var(--feedbalia-color-accent-500);

		.badge-comment {
			background-color: var(--feedbalia-color-accent-500);
		}

		.wrap-comment {
		}
	}

	login {
		#login {
			background-color: var(--feedbalia-color-background-500);
		}
	}

	mat-spinner[color="accent"] {
		background: transparent !important;
	}

	.mat-mdc-progress-spinner circle,
	body.theme-default .mat-mdc-progress-spinner circle {
		stroke: var(--feedbalia-color-primary-500) !important;
	}

	.grey-card-box {
		background-color: var(--feedbalia-color-accent-400);
	}

	.btnSelected {
		background-color: var(--feedbalia-color-accent-700) !important;
	}

	#shop {
		.card-producto {
			.img-product {
				.shop-price {
					background: linear-gradient(to right, white 0, var(--feedbalia-color-accent-500) 71%, var(--feedbalia-color-accent-700) 100%) !important;
				}
			}
		}
	}

	#perfil {
		.card-coins div table {
			background-color: var(--feedbalia-color-accent-500) !important;
		}

		.card-person {
			background-color: var(--feedbalia-color-primary-500);
			color: var(--feedbalia-color-background-500);

			.person-info .item-info a {
				color: var(--feedbalia-color-background-500);
			}
		}

		.logo-competency-iberext {
			border: 5px solid var(--feedbalia-color-primary-400);
		}
		/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
		.mat-tab-label,
		.mat-mdc-tab-link {
			color: var(--feedbalia-color-primary-400);
			flex: 1;
		}

		.mat-ink-bar {
			background-color: var(--feedbalia-color-primary-400);
		}

		.wrap-competencies .card-competencies .list-categories & > div &:hover {
			color: darkgray;
		}
	}

	.selected {
		background-color: var(--feedbalia-color-primary-500);
		color: white;
	}

	.mat-toolbar {
		background: var(--feedbalia-color-primary-500, inherit);
	}

	.footer {
		a {
			color: var(--feedbalia-color-menutext-500) !important;
		}

		span,
		mat-icon {
			color: var(--feedbalia-color-menutext-500) !important;
		}
	}

	.border-white {
		border: 1.5px solid var(--feedbalia-color-menutext-500) !important;
	}

	toolbar {
		.user-button,
		fuse-search-bar,
		.language-button,
		.chat-panel-toggle-button,
		.quick-panel-toggle-button {
			color: var(--feedbalia-color-menutext-500) !important;
		}
	}

	.fuse-card {
		//background-color: color-mix(in srgb, var(--feedbalia-color-background-500) 80%, black 20%);
		background-color: color-mix(in srgb, var(--feedbalia-color-background-500) 80%, var(--feedbalia-color-background-700) 20%);
	}

	.item-post .post-date,
	.badge-date,
	.text-muted,
	#publicaciones .text-muted a {
		color: #626d77 !important;
	}
}

body.administracion-default {
	div#container-3 > content {
		padding: 2rem;
	}
}
