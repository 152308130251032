@mixin vuelta-d-theme($theme) {
	$background: map-get($theme, background);
	$foreground: map-get($theme, foreground);
	$primary: map-get($theme, primary);
	$accent: map-get($theme, accent);
	$is-dark: map-get($theme, is-dark);

	.panel-plan-accion {
		border-color: var(--feedbalia-color-primary-500) !important;
	}

	.no-select {
		opacity: 0.3;
	}

	#sied {
		.btn-help {
			color: var(--feedbalia-color-primary-500) !important;
		}
	}

	#vuelta-d {
		.card-vuelta-d {
			border-color: var(--feedbalia-color-primary-500) !important;
		}

		.card-part-right {
			border-color: var(--feedbalia-color-primary-500) !important;
		}
		/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */
		.mat-progress-bar-buffer {
			background-color: var(--feedbalia-color-primary-400);
		}
		/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */
		.mat-progress-bar-fill::after {
			background-color: var(--feedbalia-color-primary-500) !important;
		}

		.stepTimeLine {
			.mat-stepper-vertical-line::before {
				top: -12px;
				bottom: -12px;
			}
			.mat-vertical-stepper-header {
				height: 100%;
				padding: 4px 24px;
			}
			.mat-step-header {
				.mat-step-icon.mat-step-icon-selected,
				.mat-step-icon.mat-step-icon-state-done,
				.mat-step-icon.mat-step-icon-state-edit {
					background-color: var(--feedbalia-color-primary-500) !important;
				}
				.mat-step-icon {
					background-color: var(--feedbalia-color-primary-500);
				}
				.mat-step-label.mat-step-label-active {
					width: 100%;
					display: flex;
					flex-direction: column;
					align-items: center;
					padding: 6px;
					border: 1px solid lightgray;
					font-size: 16px;
					color: #2c3e50ad !important;

					p {
						white-space: initial;
						text-align: center;
					}
				}
				.mat-step-label.mat-step-label-active.mat-step-label-selected {
					background-color: var(--feedbalia-color-primary-400);
				}

				.mat-icon {
					height: 24px !important;
					width: 24px !important;
				}
			}
			.mat-vertical-content {
				padding-bottom: 8px;
			}
		}
	}
}
