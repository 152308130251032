@use "@angular/material" as mat;
@mixin codigo-seguridad-theme($theme) {
	$background: map-get($theme, background);
	$foreground: map-get($theme, foreground);
	$primary: map-get($theme, primary);
	$accent: map-get($theme, accent);
	$is-dark: map-get($theme, is-dark);

	codigo-seguridad {
		#security-code {
			#login-form-wrapper {
				#securitycode-form {
					@if ($is-dark) {
						background: mat.get-color-from-palette($fuse-navy, 600);
					} @else {
						background: map-get($background, card);
					}
					border-top-color: var(--feedbalia-color-primary-400);

					form {
						.submit-button {
							background-color: var(--feedbalia-color-primary-400);
						}
					}

					.separator {
						color: map-get($foreground, divider);

						.text {
							&:before,
							&:after {
								border-top-color: map-get($foreground, divider);
							}
						}
					}
				}
			}
		}
	}
}
