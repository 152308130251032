@mixin chat-panel-theme($theme) {
	$background: map-get($theme, background);
	$foreground: map-get($theme, foreground);
	$primary: map-get($theme, primary);
	$accent: map-get($theme, accent);
	$is-dark: map-get($theme, is-dark);

	chat-panel {
		#contacts-list {
			background-color: map-get($background, app-bar);

			.contacts-list-item {
				&.active {
					background: map-get($background, hover);

					&:after {
						background-color: var(--feedbalia-color-primary-500);
					}
				}

				.unread-count {
					background-color: var(--feedbalia-color-primary-500);
					color: map-get($accent, default-contrast);
				}

				.status-icon {
					border-color: map-get($background, app-bar);
				}
			}
		}

		#chat {
			.messages {
				.message-row {
					&.contact {
						.bubble {
							background-color: map-get($primary, default);
							color: map-get($primary, default-contrast);
						}
					}

					&.me {
						.bubble {
							color: rgba(0, 0, 0, 0.87);
							background-color: #e0e0e0;
						}
					}
				}
			}

			.reply-form {
				.message-text {
					background-color: map-get($background, app-bar);
					border-color: map-get($foreground, divider);
					/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
					/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
					/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
					.mat-form-field-wrapper {
						/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
						/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
						.mat-form-field-flex {
							/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
							.mat-form-field-infix {
								@if ($is-dark == true) {
									background-color: map-get($background, hover);
								} @else {
									background-color: white;
								}
							}
						}
					}
				}
			}
		}
	}
}
