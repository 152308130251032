@mixin publicaciones-theme($theme) {
	$background: map-get($theme, background);
	$foreground: map-get($theme, foreground);
	$primary: map-get($theme, primary);
	$accent: map-get($theme, accent);
	$is-dark: map-get($theme, is-dark);

	#publicaciones {
		.card-border-top {
			border-top: 4px solid;
			border-top-color: var(--feedbalia-color-primary-400);
		}

		.text-muted {
			color: #b4bcc2;

			a {
				color: #b4bcc2;
			}
		}

		.btn-feedbalia {
			background-color: var(--feedbalia-color-primary-400);
			color: #fff;

			&:hover {
				opacity: 0.8;
			}
		}

		.btn-like,
		.btn-responder {
			color: var(--feedbalia-color-primary-400);
			border-color: var(--feedbalia-color-primary-400);

			&.active {
				background-color: var(--feedbalia-color-primary-400);
				color: #fff;
			}
		}
		.btn-comments {
			color: var(--feedbalia-color-primary-400);

			&:hover {
				opacity: 0.6;
			}
		}
		/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
		.mat-tab-label,
		.mat-mdc-tab-link {
			color: var(--feedbalia-color-primary-400);
			font-size: 18px;
			flex: 1;
		}

		.mat-ink-bar {
			background-color: var(--feedbalia-color-primary-400);
		}

		.busca-autor {
			/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
			.mat-form-field-flex {
				background-color: #fff;
			}
			/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
			/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
			.mat-form-field-underline {
				// background-color: var(--feedbalia-color-primary-400);
				// opacity: 0.4;
				/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
				.mat-form-field-ripple {
					background-color: var(--feedbalia-color-primary-400);
				}
			}
		}

		.btn-post {
			color: #5f5f5f;

			&:hover {
				color: #fff;
				background-color: var(--feedbalia-color-primary-400);
			}
		}
	}
}
