:root {
	--feedbalia-color-primary-50: #ffffff;
	--feedbalia-color-primary-400: #7dc7b2;
	--feedbalia-color-primary-500: #66bda4; //themePrimaryColor
	--feedbalia-color-primary-700: #53ae92;
	--feedbalia-color-primary-50-contrast: #000000;
	--feedbalia-color-primary-400-contrast: #000000;
	--feedbalia-color-primary-500-contrast: #ffffff;
	--feedbalia-color-primary-700-contrast: #ffffff;

	--feedbalia-color-vuelta-d-200: #bff7a5;
	--feedbalia-color-vuelta-d-500: #54bc23;

	--feedbalia-color-navbar-50: #ffffff;
	--feedbalia-color-navbar-400: #7dc7b2;
	--feedbalia-color-navbar-500: #66bda4; //themeNavbarBgColor
	--feedbalia-color-navbar-700: #53ae92;
	--feedbalia-color-navbar-50-contrast: #000000;
	--feedbalia-color-navbar-400-contrast: #000000;
	--feedbalia-color-navbar-500-contrast: #ffffff; //themeNavbarTextColor
	--feedbalia-color-navbar-700-contrast: #ffffff;

	--feedbalia-color-navbar2-50: #ffffff;
	--feedbalia-color-navbar2-400: #7dc7b2;
	--feedbalia-color-navbar2-500: #54bc23; //themeNavbarBgColor2
	--feedbalia-color-navbar2-700: #53ae92;
	--feedbalia-color-navbar2-50-contrast: #000000;
	--feedbalia-color-navbar2-400-contrast: #000000;
	--feedbalia-color-navbar2-500-contrast: #ffffff; //themeNavbarTextColor2
	--feedbalia-color-navbar2-700-contrast: #ffffff;

	--feedbalia-color-navbar-logo-50: #ffffff;
	--feedbalia-color-navbar-logo-400: #7dc7b2;
	--feedbalia-color-navbar-logo-500: #66bda4; //themeNavbarLogoBgColor
	--feedbalia-color-navbar-logo-700: #53ae92;
	--feedbalia-color-navbar-logo-50-contrast: #000000;
	--feedbalia-color-navbar-logo-400-contrast: #000000;
	--feedbalia-color-navbar-logo-500-contrast: #ffffff; //themeNavbarTextColor
	--feedbalia-color-navbar-logo-700-contrast: #ffffff;

	--feedbalia-color-sidebar-50: #ededed;
	--feedbalia-color-sidebar-400: #7d7d7d;
	--feedbalia-color-sidebar-500: #666666; //themeSidebarBgColor
	--feedbalia-color-sidebar-700: #53ae92;
	--feedbalia-color-sidebar-50-contrast: #000000;
	--feedbalia-color-sidebar-400-contrast: #ffffff;
	--feedbalia-color-sidebar-500-contrast: #ffffff; // themeSidebarTextColor
	--feedbalia-color-sidebar-700-contrast: #ffffff;

	--admin-color-primary-50: #a3a3a3;
	--admin-color-primary-400: #858585;
	--admin-color-primary-500: #cccc; //themePrimaryColor
	--admin-color-primary-700: #494949;
	--admin-color-primary-50-contrast: #000000;
	--admin-color-primary-400-contrast: #000000;
	--admin-color-primary-500-contrast: #ffffff;
	--admin-color-primary-700-contrast: #000000;

	--admin-color-secondary-50: #f4f4f4;
	--admin-color-secondary-400: #e7e7e7;
	--admin-color-secondary-500: #dcdcdc; //themeSecondaryColor
	--admin-color-secondary-700: #cacaca;
	--admin-color-secondary-50-contrast: #000000;
	--admin-color-secondary-400-contrast: #000000;
	--admin-color-secondary-500-contrast: #ffffff;
	--admin-color-secondary-700-contrast: #000000;

	--admin-color-sidebar-50: #ffffff;
	--admin-color-sidebar-400: #ffffff;
	--admin-color-sidebar-500: #ffffff; //themeSidebarBgColor
	--admin-color-sidebar-700: #ffffff;
	--admin-color-sidebar-50-contrast: #000000;
	--admin-color-sidebar-400-contrast: #000000;
	--admin-color-sidebar-500-contrast: #000000; // themeSidebarTextColor
	--admin-color-sidebar-700-contrast: #000000;

	--admin-color-navbar-50: #ffffff;
	--admin-color-navbar-400: #ffffff;
	--admin-color-navbar-500: #ffffff; //themenavbarBgColor
	--admin-color-navbar-700: #ffffff;
	--admin-color-navbar-50-contrast: #000000;
	--admin-color-navbar-400-contrast: #000000;
	--admin-color-navbar-500-contrast: #000000; // themenavbarTextColor
	--admin-color-navbar-700-contrast: #000000;

	--feedbalia-color-menutext-50: #ffffff;
	--feedbalia-color-menutext-400: #ffffff;
	--feedbalia-color-menutext-500: #ffffff; //themenavbarBgColor
	--feedbalia-color-menutext-700: #ffffff;
	--feedbalia-color-menutext-50-contrast: #000000;
	--feedbalia-color-menutext-400-contrast: #000000;
	--feedbalia-color-menutext-500-contrast: #000000; // themenavbarTextColor
	--feedbalia-color-menutext-700-contrast: #000000;
}
