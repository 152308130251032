// -----------------------------------------------------------------------------------------------------
@use "@angular/material" as mat;
// @ Body scroll lock
// -----------------------------------------------------------------------------------------------------
html,
body {
	display: flex;
	flex: 1 0 auto;
	width: 100%;
	height: 100%;
	max-height: 100%;
	min-height: 100%;
	margin: 0;
	padding: 0;
	overflow: hidden;
}

// -----------------------------------------------------------------------------------------------------
// @ Boxed body
// -----------------------------------------------------------------------------------------------------
body {
	// Boxed
	&.boxed {
		max-width: 1200px;
		margin: 0 auto;

		@include mat.elevation(8);
	}
}

/*----------------------------------------------------------------*/
/*  @ Text rendering & box sizing
/*----------------------------------------------------------------*/
* {
	text-rendering: optimizeLegibility;
	-o-text-rendering: optimizeLegibility;
	-ms-text-rendering: optimizeLegibility;
	-moz-text-rendering: optimizeLegibility;
	-webkit-text-rendering: optimizeLegibility;
	-webkit-tap-highlight-color: transparent;
	box-sizing: border-box;

	&:before,
	&:after {
		box-sizing: border-box;
	}

	// Remove focus outline
	&:focus {
		outline: none;
	}
}

// -----------------------------------------------------------------------------------------------------
// @ Responsive images
// -----------------------------------------------------------------------------------------------------

img {
	max-width: 100%;
	height: auto;
	vertical-align: top;
	border: none;
}

// -----------------------------------------------------------------------------------------------------
// @ Input
// -----------------------------------------------------------------------------------------------------
input {
	border: none;
	padding: 0 16px;
}

//WYSIWYG
[ng-quill-editor] {
	display: block;
}
ng-quill-toolbar {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background-color: white;
	display: block;
}
.ql-container {
	min-height: 100px;
}
.ql-editor {
	//max-height: calc(100vh - 40px);
	//margin-top: 40px;
	-webkit-user-select: text !important;
	-khtml-user-select: text !important;
	-moz-user-select: text !important;
	-o-user-select: text !important;
	user-select: text !important;
}
//FIN ESTILOS WYSIWYG

.fullwidth {
	width: 100% !important;
}

.grow1 {
	flex-grow: 1;
}

.grow2 {
	flex-grow: 2;
}

.center-text {
	text-align: center;
}

.pointer {
	cursor: pointer;
}

.cdk-global-overlay-wrapper {
	overflow: auto !important;
}

.img-competency-timeline {
	position: relative;
	width: 32px;
	height: auto;
	margin-top: 10px;
	margin-right: 5px;
	z-index: 5;
}
