.docs {
	font-size: 16px;

	> .content {
		max-width: 980px;

		> .main-title {
			&:first-child {
				margin-top: 0;
			}
		}
	}

	.main-title {
		display: flex;
		margin-top: 72px;
		font-size: 24px;
	}

	.section-title {
		display: inline-flex;
		font-size: 18px;
		margin-top: 24px;
		border-bottom: 1px solid #f44336;
		color: #f44336;
	}

	ol,
	ul {
		padding-left: 24px;

		li {
			margin-bottom: 12px;
			line-height: 1.7;
		}
	}

	p {
		line-height: 1.7;
	}
}
