@mixin expenses-theme($theme) {
	$background: map-get($theme, background);
	$foreground: map-get($theme, foreground);
	$primary: map-get($theme, primary);
	$accent: map-get($theme, accent);
	$is-dark: map-get($theme, is-dark);

	#expenses {
		.filter-container {
			background-color: #ccc;

			.filter-wrapper {
				// background: #fff;
				background: map-get($background, card);

				.filter-expense {
					input {
						background: map-get($background, card);
						color: map-get($foreground, text);
					}
				}
			}
		}

		.btn-feedbalia {
			border-radius: 50px;
		}

		.md-drppicker {
			td.active {
				background-color: var(--feedbalia-color-primary-400);

				&:hover {
					background-color: var(--feedbalia-color-primary-400);
				}
			}

			.btn {
				background-color: var(--feedbalia-color-primary-400);

				&:hover,
				&:focus {
					background-color: var(--feedbalia-color-primary-400);
				}
			}

			.ranges ul li button.active {
				background-color: var(--feedbalia-color-primary-400);
				border-radius: 4px;
			}
		}
	}
}
