body:not(.is-mobile) {
	::-webkit-scrollbar {
		width: 12px;
		height: 12px;
		background-color: rgba(0, 0, 0, 0);
	}

	::-webkit-scrollbar:hover {
		background-color: rgba(0, 0, 0, 0.12);
	}

	::-webkit-scrollbar-thumb {
		border: 2px solid transparent;
		box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.37);
		border-radius: 12px;
	}

	::-webkit-scrollbar-thumb:active {
		box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.54);
		border-radius: 12px;
	}
}
