@use "@angular/material" as mat;
@mixin inicio-theme($theme) {
	$background: map-get($theme, background);
	$foreground: map-get($theme, foreground);
	$primary: map-get($theme, primary);
	$accent: map-get($theme, accent);
	$is-dark: map-get($theme, is-dark);

	.card-border-top {
		border-top: 4px solid;
		border-top-color: var(--feedbalia-color-primary-500);
	}

	.text-muted {
		color: #b4bcc2;
	}
	.text-theme {
		color: var(--feedbalia-color-primary-400);
	}
	.text-success {
		color: #3c763d;
	}
	.text-warning {
		color: #8a6d3b;
	}
	.text-danger {
		color: #a94442;
	}

	.alert-muted {
		border-color: #6c757d;
		color: #6c757d;
	}
	.alert-success {
		background-color: #dff0d8;
		border-color: #d6e9c6;
		color: #3c763d;
	}
	.alert-warning {
		background-color: #fcf8e3;
		border-color: #faebcc;
		color: #8a6d3b;
	}
	.alert-danger {
		background-color: #f2dede;
		border-color: #ebccd1;
		color: #a94442;
	}

	.btn-success {
		color: #fff !important;
		background-color: #5cb85c !important;
		border-color: #4cae4c;
	}
	.btn-warning {
		color: #fff !important;
		background-color: #f0ad4e !important;
		border-color: #eea236;
	}
	.btn-danger {
		color: #fff !important;
		background-color: #d9534f !important;
		border-color: #d43f3a;
	}
	.btn-muted {
		color: #333 !important;
		background-color: #f7f7f7 !important;
		border-color: #ccc;
	}

	.btn-feedbalia {
		background-color: var(--feedbalia-color-primary-400);
		color: #fff;

		&:hover {
			opacity: 0.8;
		}

		mat-icon {
			font-size: 18px;
			width: 20px;
			height: 20px;
			min-height: 18px;
			min-width: 18px;
		}
	}

	.btn-outline {
		&:hover {
			background-color: #ccc;
		}
	}

	.btn-disable {
		background-color: #0000001f;
		color: #00000042;
		cursor: default;
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);

		&:hover {
			opacity: 1;
		}
	}

	.content-comment {
		background-color: #e7e7e8;
		border-radius: 4px;
	}

	.tooltip-lines {
		white-space: pre-line;
	}

	.message-box {
		position: relative;
		padding: 12px 16px;

		.close-button {
			color: #fff;
			position: absolute;
			right: 0;
			top: 0;
		}
	}

	#inicio {
		.bienvenida {
			.clima-container {
				.clima-icon {
					background-color: var(--feedbalia-color-primary-400);
				}
			}
		}

		.btn-like,
		.btn-responder {
			color: var(--feedbalia-color-primary-400);
			border-color: var(--feedbalia-color-primary-400);

			&.active {
				background-color: var(--feedbalia-color-primary-400);
				color: #fff;
			}
		}
		.btn-comments {
			color: var(--feedbalia-color-primary-400);

			&:hover {
				opacity: 0.6;
			}
		}

		.tipo-post {
			small {
				color: var(--feedbalia-color-primary-400);
			}
			.btn-fijar-post {
				color: #f3b854;

				&:hover {
					opacity: 0.7;
				}
			}
		}
	}

	.form-control {
		display: block;
		width: 100%;
		height: 34px;
		padding: 6px 12px;
		font-size: 12px;
		line-height: 1.42857143;
		color: #555;
		background-color: #fff;
		background-image: none;
		border: 1px solid #ccc;
		border-radius: 4px;
		-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
		-webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
		-o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
		transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

		&:focus {
			border-color: #666;
		}
	}
	.form-control.disabled {
		background-color: #e7e7e8;
		cursor: not-allowed;
	}

	.alert-info {
		background-color: #d9edf7;
		border-color: #bce8f1;
		color: #31708f;
	}

	.alert {
		padding: 15px;
		margin-bottom: 12px;
		border: 1px solid transparent;
		border-radius: 4px;
	}

	.panel-feedbalia {
		.mat-mdc-dialog-container {
			border-top: 8px solid;
			border-top-color: var(--feedbalia-color-primary-400);
		}

		.mat-mdc-dialog-title {
			color: var(--feedbalia-color-primary-400);
		}

		.mat-mdc-dialog-content {
			padding-bottom: 16px;
		}

		.mat-mdc-dialog-actions {
			border-top: 1px solid #ccc;

			.mat-mdc-button {
				border: 1px solid #8c8c8c;

				mat-icon {
					font-size: 18px;
					width: 18px;
					height: 18px;
					min-height: 18px;
					min-width: 18px;
					line-height: 18px;
				}
			}
		}

		&.dar-badge {
			.mat-mdc-dialog-container {
				overflow: visible;
			}
		}

		@media screen and (max-width: 768px) {
			max-width: 100% !important;
			min-width: 90% !important;
		}
	}

	.pl-10 {
		padding-left: 10px;
	}

	.panel-admin {
		.mat-mdc-dialog-container {
			border-radius: 0px !important;
		}

		// max-width: 800px !important;
		// min-width: 70%;
	}

	.panel-admin-usuario {
		.mat-mdc-dialog-container {
			padding-bottom: 0px;
		}
	}

	.panel-evaluacion {
		.mat-mdc-dialog-container {
			border-top: 8px solid;
			border-top-color: var(--feedbalia-color-primary-400) !important;
		}

		.mat-mdc-dialog-title {
			color: var(--feedbalia-color-primary-400) !important;
		}

		.mat-mdc-dialog-content {
			padding-bottom: 16px;
		}

		.btn-feedbalia {
			background-color: var(--feedbalia-color-primary-400) !important;
		}

		.btn-borrador {
			color: var(--feedbalia-color-primary-400) !important;
		}

		@media screen and (max-width: 768px) {
			max-width: 100% !important;
			min-width: 90% !important;
		}
	}

	.panel-vuelta-d {
		.mat-mdc-dialog-container {
			border-top: 8px solid;
			border-top-color: var(--feedbalia-color-primary-500) !important;
		}

		.mat-mdc-dialog-title {
			color: var(--feedbalia-color-primary-500) !important;
		}

		.mat-mdc-dialog-content {
			padding-bottom: 16px;
		}

		.mat-mdc-button {
			background-color: var(--feedbalia-color-primary-500) !important;
		}
	}

	.badge {
		display: inline-block;
		min-width: 10px;
		padding: 3px 7px;
		font-size: 12px;
		font-weight: bold;
		color: #fff;
		line-height: 1;
		vertical-align: middle;
		white-space: nowrap;
		text-align: center;
		background-color: #777;
		border-radius: 10px;
	}

	.badge-theme {
		background-color: var(--feedbalia-color-primary-400);
	}
	.badge-success {
		background-color: #4cae4c;
	}
	.badge-soft-success {
		background-color: #a5cc55;
	}
	.badge-danger {
		background-color: #d43f3a;
	}
	.badge-warning {
		background-color: #eea236;
	}

	.person-avatar.avatar-30 {
		img {
			width: 30px;
			height: 30px;
			object-fit: cover;
		}
	}
	.person-avatar.avatar-35 {
		img {
			width: 35px;
			height: 35px;
			object-fit: cover;
		}
	}
	.person-avatar.avatar-77 {
		img {
			width: 77px;
			height: 77px;
			object-fit: cover;
		}
	}

	.panel-theme,
	.panel-theme .panel-body-border {
		border-color: mat.get-color-from-palette($accent, 400) !important;
	}
	.panel-theme .panel-heading {
		background-color: var(--feedbalia-color-primary-400);
		color: #fff;
		border-color: var(--feedbalia-color-primary-400);
	}

	.kpi-table {
		input:checked::after {
			background-color: var(--feedbalia-color-primary-400);
			border-color: var(--feedbalia-color-primary-400);
		}
	}

	.error-snackbar {
		background: #cc3300;
		white-space: pre-wrap;
		color: white;
	}

	.warn-snackbar {
		background: #ffe600;
		white-space: pre-wrap;
		color: black;
	}

	.card-vuelta {
		max-width: 100%;
		width: 100% !important;

		margin-bottom: 12px;
		border-top-color: var(--feedbalia-color-primary-500) !important;
		padding: 2rem;

		.user-container {
			h5 {
				margin: 1rem 0;
			}
		}

		.data-container {
			margin-left: 5rem;

			.clickable {
				cursor: pointer;
			}

			.vueltas {
				font-weight: bold;
			}

			a {
				color: black;
			}

			a:hover {
				text-decoration: none !important;
			}
		}
	}
}

.btn-vuelta-d {
	width: 50rem;
	background-color: var(--feedbalia-color-primary-500) !important;
}
.disabled-button {
	width: 50rem;
	background-color: gray !important;
	opacity: 0.5;
}
