@mixin perfil-theme($theme) {
	$background: map-get($theme, background);
	$foreground: map-get($theme, foreground);
	$primary: map-get($theme, primary);
	$accent: map-get($theme, accent);
	$is-dark: map-get($theme, is-dark);

	#perfil {
		.btn-like,
		.btn-responder {
			color: var(--feedbalia-color-primary-400);
			border-color: var(--feedbalia-color-primary-400);

			&.active {
				background-color: var(--feedbalia-color-primary-400);
				color: #fff;
			}
		}
		.btn-comments {
			color: var(--feedbalia-color-primary-400);

			&:hover {
				opacity: 0.6;
			}
		}
		/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
		.mat-tab-label,
		.mat-mdc-tab-link {
			color: var(--feedbalia-color-primary-400);
			flex: 1;
		}

		.mat-ink-bar {
			background-color: var(--feedbalia-color-primary-400);
		}
	}

	.botonsyrsa {
		color: black;
	}
}
